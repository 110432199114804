import type { FetchNextPageOptions, InfiniteQueryObserverResult } from '@tanstack/react-query'
import type { InfiniteQueryPaginatedResp } from '../../lib/hooks/usePosts'
import type { FilterOptionValue, FilterOptions } from './Filter'
import React, { useMemo, useState } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import InfiniteScroll from 'react-infinite-scroll-component'
import CircularProgress from '@mui/material/CircularProgress'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import FeedIcon from '@mui/icons-material/Feed'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import { useSession } from 'next-auth/react'
import PageName from '../PageName'
import ScrollTop from '../ScrollTop'
import Post from '../posts/Post'
import PostSkeleton from '../posts/PostSkeleton'
import AdvancedSearchForm from './AdvancedSearchForm'
import Filter from './Filter'

type FeedProps<T extends boolean> = {
    data: InfiniteQueryPaginatedResp | undefined
    isLoading: boolean
    fetchNextPage: (
        options?: FetchNextPageOptions | undefined
    ) => Promise<InfiniteQueryObserverResult<InfiniteQueryPaginatedResp>>
    hasNextPage: boolean
    type: FilterOptionValue<T> | ''
    setType: (type: FilterOptionValue<T> | '') => void
    headerText?: string
    hasQueryParams?: boolean
    entityType?: DTO.SearchResultType
}

const initialCount = 20

const Feed = <IncludeFollowing extends boolean = false>({
    data,
    isLoading,
    fetchNextPage,
    hasNextPage,
    type,
    setType,
    includeFollowing,
    headerText = 'Feed',
    hasQueryParams = undefined,
    entityType = undefined,
}: FeedProps<IncludeFollowing> &
    (IncludeFollowing extends true
        ? { includeFollowing: IncludeFollowing }
        : { includeFollowing?: IncludeFollowing })): JSX.Element => {
    const { data: user } = useSession()

    const [showFilters, setShowFilters] = useState<boolean>(!!hasQueryParams)

    const isIntelType = type === 'INTEL'
    const options = useMemo<FilterOptions<IncludeFollowing>[]>(() => {
        const defaultOptions: FilterOptions<IncludeFollowing>[] = [
            { label: 'All Posts', value: '', key: '' },
            { label: 'My Posts', value: 'MY_POSTS', key: 'MYPOSTS' },
        ]

        if (user?.roles.contentPermissions['pro-scouting-reports']) {
            defaultOptions.push({ label: 'Pro Scouting', value: 'PRO_SCOUTING', key: 'PRO_SCOUTING' })
        }
        if (user?.roles.contentPermissions['amateur-scouting-reports']) {
            defaultOptions.push({ label: 'Am Scouting', value: 'AM_SCOUTING', key: 'AM_SCOUTING' })
        }
        if (
            user?.roles.contentPermissions['pro-intel-medical-physical'] ||
            user?.roles.contentPermissions['pro-intel-off-court'] ||
            user?.roles.contentPermissions['pro-intel-on-court'] ||
            user?.roles.contentPermissions['pro-intel-strategy']
        ) {
            defaultOptions.push({ label: 'Pro Intel', value: 'PRO_INTEL', key: 'PRO_INTEL' })
        }
        if (
            user?.roles.contentPermissions['amateur-intel-medical-physical'] ||
            user?.roles.contentPermissions['amateur-intel-off-court'] ||
            user?.roles.contentPermissions['amateur-intel-on-court'] ||
            user?.roles.contentPermissions['amateur-intel-strategy']
        ) {
            defaultOptions.push({ label: 'Am Intel', value: 'AM_INTEL', key: 'AM_INTEL' })
        }
        if (
            user?.roles.contentPermissions['knicks-player-development-coaching'] ||
            user?.roles.contentPermissions['knicks-player-development-physical'] ||
            user?.roles.contentPermissions['westchester-player-development-coaching'] ||
            user?.roles.contentPermissions['westchester-player-development-physical']
        ) {
            defaultOptions.push({ label: 'Player Dev', value: 'PLAYER_DEVELOPMENT', key: 'PLAYER_DEVELOPMENT' })
        }
        if (
            user?.roles.contentPermissions['knicks-community'] ||
            user?.roles.contentPermissions['westchester-community']
        ) {
            defaultOptions.push({ label: 'Community', value: 'COMMUNITY', key: 'COMMUNITY' })
        }
        if (user?.roles.contentPermissions['glg-game-reports'] || user?.roles.contentPermissions['nba-game-reports']) {
            defaultOptions.push({ label: 'Game', value: 'GAME', key: 'GAME' })
        }
        if (user?.roles.contentPermissions['workout-reports']) {
            defaultOptions.push({ label: 'Workout', value: 'WORKOUT', key: 'WORKOUT' })
        }

        if (includeFollowing) {
            const opt = { label: 'Following', value: 'FOLLOWING', key: 'FOLLOWING' } as FilterOptions<IncludeFollowing>
            defaultOptions.unshift(opt)
        }

        // user was redirected from a player's Intel page
        if (isIntelType) defaultOptions.unshift({ label: 'All Intel', value: 'INTEL', key: 'INTEL' })

        return defaultOptions
    }, [user?.roles.contentPermissions, includeFollowing, isIntelType])

    const filteredPosts = useMemo<DTO.Post[]>(() => {
        const postMap = new Map<string, DTO.Post>()
        data?.pages
            .flatMap((page) => page.results)
            .forEach((d) => {
                postMap.set(d.postID, d)
            })
        return Array.from(postMap.values())
    }, [data])

    const numOfPosts = filteredPosts.length || undefined

    return (
        <Grid container direction="column" flexGrow={0}>
            <Grid id="back-to-top" item sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box display="flex">
                    <PageName text={headerText} />
                    {hasQueryParams !== undefined && (
                        <IconButton
                            onClick={() => setShowFilters(!showFilters)}
                            disabled={isLoading}
                            sx={{ position: 'relative', bottom: 4 }}
                        >
                            {showFilters && (
                                <FilterAltIcon sx={{ color: hasQueryParams ? 'primary.main' : undefined }} />
                            )}
                            {!showFilters && (
                                <FilterAltOutlinedIcon sx={{ color: hasQueryParams ? 'primary.main' : undefined }} />
                            )}
                        </IconButton>
                    )}
                </Box>
                <Box>
                    <Filter<IncludeFollowing> option={type} setOption={setType} options={options} />
                </Box>
            </Grid>
            {showFilters && (
                <Grid marginBottom={1}>
                    <AdvancedSearchForm type={type} entityType={entityType} />
                </Grid>
            )}
            {isLoading &&
                Array(4)
                    .fill('')
                    .map((_, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Grid key={index} item paddingBottom={1}>
                            <PostSkeleton />
                        </Grid>
                    ))}
            {numOfPosts === 0 ? (
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" marginTop={4}>
                    <Typography variant="h6">No Posts To View</Typography>
                    <FeedIcon sx={{ color: 'text.secondary' }} fontSize="large" />
                </Box>
            ) : (
                <Box sx={{ maxWidth: '100%' }}>
                    <InfiniteScroll
                        dataLength={numOfPosts || initialCount}
                        hasMore={hasNextPage}
                        next={fetchNextPage}
                        loader={
                            <Stack direction="row" justifyContent="center" marginTop={1} marginBottom={2}>
                                <CircularProgress color="info" />
                            </Stack>
                        }
                        endMessage={
                            !isLoading && <ScrollTop anchorTagName="#back-to-top" text="No more posts to view" />
                        }
                    >
                        {filteredPosts.map((p) => (
                            <Grid key={p.postID} item paddingBottom={1}>
                                <Post post={p} />
                            </Grid>
                        ))}
                    </InfiniteScroll>
                </Box>
            )}
        </Grid>
    )
}

export default Feed

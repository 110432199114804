export const mapNewAutocompleteEntryToSearchResult = (
    result: string | DTO.CreateSearchResult
): DTO.CreateSearchResult =>
    typeof result === 'string'
        ? {
              id: null,
              title: result,
              subtitle: null,
              description: null,
              urlSlug: null,
              imageUrl: null,
              teamId: null,
              type: 'other',
              teamAbbr: null,
          }
        : result

export const mapSearchResultToTag = (x: DTO.SearchResult): DTO.PostTag => {
    const tag = {
        name: x.title,
        abbr: x.title,
        entityId: x.id,
        entitySlug: x.urlSlug,
        teamId: x.teamId,
    }

    if (x.type === 'player') {
        return {
            ...tag,
            entityType: 'player',
            playerHeadshot: x.imageUrl,
            teamName: x.description,
            teamAbbr: null,
            teamLogoUrl: null,
            level: null,
        }
    }
    if (x.type === 'team') {
        return {
            ...tag,
            teamId: x.id,
            entityType: 'team',
            teamLogoUrl: x.imageUrl,
            abbr: x.teamAbbr || x.title,
        }
    }

    return { ...tag, entityType: x.type as 'agent' }
}

export const mapCreateSearchResultToTag = (x: DTO.CreateSearchResult): DTO.CreatePostTag => ({
    name: x.title,
    abbr: x.title,
    entityId: x.id,
    entitySlug: x.urlSlug,
    entityType: 'other',
    type: 'other',
})

export const mapTeamOrgPlayerToTag = (x: DTO.TeamOrgPlayer, teamId?: string): DTO.PlayerTag => ({
    name: x.name,
    abbr: x.name,
    entityId: x.playerId,
    entityType: 'player',
    entitySlug: x.urlSlug,
    level: 'PRO',
    teamId: teamId || null,
    teamAbbr: null,
    teamLogoUrl: null,
    teamName: null,
    playerHeadshot: null,
})

export const mapSearchResultToEntity = (
    x: DTO.SearchResult
): Pick<DTO.Entity, 'entityId' | 'name' | 'type' | 'urlSlug'> => ({
    entityId: x.id,
    name: x.title,
    // @ts-expect-error type mismatch that will/should not be an issue under the right context this mapping gets used
    type: x.type,
    urlSlug: x.urlSlug,
})
export const mapCreateSearchResultToCreatePostEntity = (x: DTO.CreateSearchResult): DTO.CreatePostEntity => ({
    entityId: x.id,
    name: x.title,
    // @ts-expect-error type mismatch that will/should not be an issue under the right context this mapping gets used
    type: x.type,
    urlSlug: x.urlSlug,
})

export const mapTagToSearchResult = (x: DTO.PostTag): DTO.SearchResult => ({
    title: x.name,
    id: x.entityId,
    type: x.entityType,
    urlSlug: x.entitySlug,
    subtitle: null,
    description: null,
    imageUrl: null,
    teamId: (x.entityType === 'player' && x.teamId) || null,
    teamAbbr: (x.entityType === 'team' && x.abbr) || null,
})

export const mapEntityToSearchResult = (
    x: Pick<DTO.Entity, 'entityId' | 'name' | 'type' | 'urlSlug'>
): DTO.SearchResult => ({
    id: x.entityId,
    title: x.name,
    type: x.type,
    urlSlug: x.urlSlug,
    subtitle: null,
    description: null,
    imageUrl: null,
    teamId: null,
    teamAbbr: null,
})

// @ts-expect-error "type of id incompatible" error here is taken into account under the right context
export const mapCreatePostEntityToCreateSearchResult = (x: DTO.CreatePostEntity): DTO.CreateSearchResult => ({
    id: x.entityId,
    title: x.name,
    type: x.type,
    urlSlug: x.urlSlug,
    subtitle: null,
    description: null,
    imageUrl: null,
    teamId: null,
})

export const mapPlayerToSearchResult = (
    player: Pick<DTO.Player, 'playerId' | 'fullName' | 'urlSlug' | 'imageUrl' | 'teamId'>
): DTO.SearchResult => ({
    id: player.playerId,
    title: player.fullName,
    type: 'player',
    subtitle: '',
    description: '',
    urlSlug: player.urlSlug,
    imageUrl: player.imageUrl,
    teamId: player.teamId,
    teamAbbr: null,
})

export const mapTeamToSearchResult = (
    team: Pick<DTO.Team, 'teamId' | 'teamName' | 'url' | 'teamAbbreviation'>
): DTO.SearchResult => ({
    id: team.teamId,
    title: team.teamName,
    type: 'team',
    subtitle: '',
    description: '',
    urlSlug: '',
    imageUrl: team.url,
    teamId: team.teamId,
    teamAbbr: team.teamAbbreviation,
})
